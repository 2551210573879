import React from "react";
import "firebase/firestore";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import { FirestoreDocument } from "@react-firebase/firestore";
import { Redirect } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import CoinIcon from "@material-ui/icons/MoneyRounded";

export default function Dashboard() {
	return (
		<FirebaseAuthConsumer>
			{({ isSignedIn, user, providerId }) => {
				if (isSignedIn) {
					console.log("[DEBUG] User info:");
					console.log(user);
					return (
						<React.Fragment>
							{user.displayName && (
								<h1>Willkommen zurück, {user.displayName}!</h1>
							)}
							{!user.displayName && <h1>Willkommen zurück!</h1>}
							<FirestoreDocument path={`/userdata/${user.uid}`}>
								{(d) => {
									if (d.isLoading) {
										return (
											<p>
												<b>Lade Benutzerdaten...</b>
											</p>
										);
									}
									return (
										<React.Fragment>
											{d.value["coins"] && (
												<Card>
													<CardContent>
														<h5>
															<CoinIcon /> Coins
														</h5>
														<h6>{d.value["coins"]}</h6>
													</CardContent>
												</Card>
											)}
										</React.Fragment>
									);
								}}
							</FirestoreDocument>
						</React.Fragment>
					);
				} else {
					return <Redirect to="/login" />;
				}
			}}
		</FirebaseAuthConsumer>
	);
}
