/* eslint-disable import/first */
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import firebaseConfig from "./config/firebase";
firebase.initializeApp(firebaseConfig);
firebase.auth().languageCode = "de";

import Container from "@material-ui/core/Container";
import "./App.css";
import Home from "./routes/Home";
import Login from "./routes/Login";
import LoginPhone from "./routes/LoginPhone";
import Dashboard from "./routes/Dashboard";
import Navbar from "./components/Navbar";
import { FirebaseAuthProvider } from "@react-firebase/auth";
import { FirestoreProvider } from "@react-firebase/firestore";

function App() {
	return (
		<FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
			<FirestoreProvider firebase={firebase} {...firebaseConfig}>
				<Router>
					<Navbar />
					<Container>
						<Switch>
							<Route path="/login/phone">
								<LoginPhone />
							</Route>
							<Route path="/login">
								<Login />
							</Route>
							<Route path="/dashboard">
								<Dashboard />
							</Route>
							<Route path="/">
								<Home />
							</Route>
						</Switch>
					</Container>
				</Router>
			</FirestoreProvider>
		</FirebaseAuthProvider>
	);
}

export default App;
