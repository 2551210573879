import React from "react";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import firebase from "firebase/app";
import "firebase/auth";

import { FirebaseAuthConsumer } from "@react-firebase/auth";

type LoginPhoneProps = {};

type LoginPhoneState = {
	value: string;
	codeSent: boolean;
	confirmationResult: firebase.auth.ConfirmationResult | null;
	verificationCodeValue: string;
	recaptchaWidgetId: string;
	error: string | false;
};

export default class LoginPhone extends React.Component<
	LoginPhoneProps,
	LoginPhoneState
> {
	recaptchaVerifier: any;

	constructor(props: LoginPhoneProps) {
		super(props);
		this.recaptchaVerifier = null;
		this.state = {
			value: "",
			codeSent: false,
			confirmationResult: null,
			verificationCodeValue: "",
			recaptchaWidgetId: "",
			error: false,
		};
	}

	componentDidMount() {
		this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
			"recaptcha-container"
		);
		this.recaptchaVerifier.render().then((widgetId: string) => {
			this.setState({
				recaptchaWidgetId: widgetId,
			});
		});
	}

	render() {
		return (
			<div>
				<FirebaseAuthConsumer>
					{({ isSignedIn, user, providerId }) => {
						if (isSignedIn) {
							return <Redirect to="/dashboard" />;
						}
					}}
				</FirebaseAuthConsumer>
				<React.Fragment>
					<h1>Anmelden mit Handynummer</h1>
					{this.state.error && (
						<Alert severity="error">
							<AlertTitle>Fehler</AlertTitle>
							{this.state.error}
						</Alert>
					)}
					{this.state.codeSent && (
						<Alert severity="success">
							Wir haben dir einen Bestätigungscode geschickt. Bitte gib diesen
							unten ein.
						</Alert>
					)}
					{!this.state.codeSent && (
						<React.Fragment>
							<p>
								<b>Bitte gib unten deine Handynummer ein:</b>
							</p>
							<PhoneInput
								placeholder="Handynummer"
								value={this.state.value}
								onChange={(value: string) => {
									this.setState({ value: value });
								}}
								defaultCountry="DE"
							/>
						</React.Fragment>
					)}
					{this.state.codeSent && (
						<React.Fragment>
							<p>
								<b>
									Bitte gib den Bestätigungscode ein, den wir an deine
									Handynummer geschickt haben:
								</b>
							</p>
							<TextField
								placeholder="Bestätigungscode"
								value={this.state.verificationCodeValue}
								onChange={(event: React.ChangeEvent) => {
									this.setState({
										// @ts-ignore
										verificationCodeValue: event.target.value,
									});
								}}
							/>
						</React.Fragment>
					)}
					<br />
					{!this.state.codeSent && <div id="recaptcha-container" />}
					<br />
					<Button
						variant="contained"
						color="primary"
						size="large"
						id="login-button"
						onClick={() => {
							if (!this.state.codeSent) {
								firebase
									.auth()
									.signInWithPhoneNumber(
										this.state.value,
										this.recaptchaVerifier
									)
									.then((confirmationResult) => {
										this.setState({
											codeSent: true,
											confirmationResult: confirmationResult,
										});
									})
									.catch((error) => {
										this.setState({
											error: error.message,
										});
									});
							} else {
								this.state.confirmationResult
									?.confirm(this.state.verificationCodeValue)
									.then((result) => {
										console.log(result);
									})
									.catch((error) => {
										this.setState({
											error: error.message,
										});
									});
							}
						}}
					>
						Anmelden
					</Button>
				</React.Fragment>
			</div>
		);
	}
}
