const config = {
	apiKey: "AIzaSyCz5eE6aikwhvCDkudqRbc499HN527iaSU",
	authDomain: "mn-schulewtf.firebaseapp.com",
	databaseURL:
		"https://mn-schulewtf-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "mn-schulewtf",
	storageBucket: "mn-schulewtf.appspot.com",
	messagingSenderId: "1054004623234",
	appId: "1:1054004623234:web:4f4bca24fcdfd8f17d019b",
	measurementId: "G-99W4H1PGXN",
};

export default config;
