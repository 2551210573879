import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			margin: theme.spacing(1),
		},
	})
);

export default function Login() {
	const classes = useStyles();

	return (
		<div>
			<Button
				variant="contained"
				color="primary"
				size="large"
				className={classes.button}
				startIcon={<PhoneIcon />}
				component={RouterLink}
				to="/login/phone"
			>
				Anmelden mit Handynummer
			</Button>
			<br />
			<Button
				variant="contained"
				color="primary"
				size="large"
				className={classes.button}
				startIcon={<EmailIcon />}
				component={RouterLink}
				to="/login/email"
			>
				Anmelden mit E-Mail
			</Button>
		</div>
	);
}
